export default {
    root: ({props, state, attrs}) => ({
        class: [
            // Display and Position
            'flex',
            'relative',
            // Size
            [attrs.size === 's' ? 'w-[100px]' : 'w-full'],
            ' h-[38px]',
            // Color and Background
            {'bg-surface-0': !props.disabled},
            'border',
            {'border-surface-300': !props.invalid},
            // Shape
            'rounded-md',
            // Invalid State
            'invalid:focus:ring-red-200',
            'invalid:hover:border-red-500',
            {'border-error': props.invalid},
            // Transitions
            'transition-all',
            'duration-200',
            // States
            {'hover:border-hover': !props.invalid},
            {
                'border-hover': state.focused,
            },
            // Misc
            'cursor-pointer',
            'select-none',
            {
                'bg-surface-200 select-none pointer-events-none cursor-default': props.disabled,
            },
        ],
    }),
    input: ({props}) => {
        return {
            class: [
                // Color and Background
                {
                    'text-surface-800': props.modelValue !== null,
                    'text-surface-400': props.modelValue === null,
                },
                'placeholder:text-surface-400',
                // Sizing and Spacing
                'flex-1 py-2 px-3',
                {'pr-7': props.showClear},
                //Shape
                'rounded-none',
                // Transitions
                'transition',
                'duration-200',
                // Misc
                'relative',
                'cursor-pointer',
                'overflow-hidden overflow-ellipsis',
                'whitespace-nowrap',
            ],
        };
    },
    trigger: {
        class: [
            'flex items-center justify-center',
            'bg-primary text-white',
            '!w-[42px] h-full',
            'rounded-r-md',
        ],
    },
    panel: {
        class: [
            'bg-surface-0',
            'text-surface-700',
            'border border-surface-300',
            'rounded-md',
            'shadow-md',
        ],
    },
    wrapper: {
        class: ['max-h-[200px]', 'overflow-auto'],
    },
    list: {
        class: 'p-1 list-none m-0',
    },
    item: ({context}) => ({
        class: [
            'relative',
            // Font
            'leading-none',
            // Spacing
            'm-0 px-3 py-2',
            'first:mt-0 mt-[2px]',
            // Shape
            'border-0 rounded',
            // Colors
            {
                'text-surface-700': !context.focused && !context.selected,
                'bg-surface-200': context.focused && !context.selected,
                'text-primary-highlight-inverse': context.selected,
                'bg-primary-highlight': context.selected,
            },
            //States
            {
                'hover:bg-surface-100': !context.focused && !context.selected,
            },
            {'hover:bg-surface-100': context.selected},
            {
                'hover:bg-surface-200': context.focused && !context.selected,
            },
            // Transition
            'transition-shadow duration-200',
            // Misc
            'cursor-pointer overflow-hidden whitespace-nowrap',
        ],
    }),
    itemgroup: {
        class: ['font-semibold', 'm-0 py-2 px-3', 'text-surface-400', 'cursor-auto'],
    },
    emptymessage: {
        class: ['leading-none', 'py-2 px-3', 'text-surface-800', 'bg-transparent'],
    },
    header: {
        class: [
            'pt-2 px-2 pb-0',
            'm-0',
            'border-b-0',
            'rounded-tl-md',
            'rounded-tr-md',
            'text-surface-700',
            'bg-surface-0',
            'border-surface-300',
        ],
    },
    filtercontainer: {
        class: 'relative',
    },
    filterinput: {
        class: [
            'leading-[normal]',
            'py-2 pl-3 pr-7',
            '-mr-7',
            'w-full',
            'text-surface-700',
            'bg-surface-0',
            'border-surface-200',
            'border',
            'rounded-lg',
            'appearance-none',
            'transition',
            'duration-200',
            'hover:border-surface-400',
            'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 focus:z-10',
            'appearance-none',
        ],
    },
    filtericon: {
        class: ['absolute', 'top-1/2 right-3', '-mt-2'],
    },
    clearicon: {
        class: ['text-surface-400', 'absolute', 'top-1/2', 'right-12', '-mt-2'],
    },
    loadingicon: {
        class: 'text-surface-400 animate-spin',
    },
    transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass:
            'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0',
    },
};
