import API from '../../api/api';

const SAVE_USER_FLAGS = 'SAVE_USER_FLAGS',
    DISABLE_LOADING = 'DISABLE_LOADING',
    SET_OPTIMIZED_SEAT_DAY_BOOKING = 'SET_OPTIMIZED_SEAT_DAY_BOOKING';

const state = () => ({
    userFlags: null,
    loading: true,
    optimizedSeatDayBooking: null,
});

const actions = {
    /**
     *
     * @param {function} commit commits mutation
     * @param {Object} state modules source of truth
     * @param {Object} userFlags user flags object
     */
    saveUserFlags({commit, state}, userFlags) {
        commit(SAVE_USER_FLAGS, userFlags);

        if (state.loading === true) {
            commit(DISABLE_LOADING);
        }
    },
    /**
     *
     * @param {function} commit commits mutation
     */
    disableLoadingState({commit}) {
        commit(DISABLE_LOADING);
    },
    async fetchOptimizedSeatDayBookingFlag({commit, state}) {
        if (state.optimizedSeatDayBooking !== null) {
            return;
        }
        try {
            const value = await API.get('/api/flag/optimized-seat-day-booking').then(response => {
                return response.status === 201;
            });
            commit(SET_OPTIMIZED_SEAT_DAY_BOOKING, value);
        } catch (error) {
            commit(SET_OPTIMIZED_SEAT_DAY_BOOKING, false);
        }
    },
};

const mutations = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Object} userFlags user flags object
     */
    [SAVE_USER_FLAGS](state, userFlags) {
        state.userFlags = userFlags;
    },
    /**
     *
     * @param {Object} state modules source of truth
     */
    [DISABLE_LOADING](state) {
        state.loading = false;
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {boolean} featureFlagValue value of the feature flag determined by the API
     */
    [SET_OPTIMIZED_SEAT_DAY_BOOKING](state, featureFlagValue) {
        state.optimizedSeatDayBooking = featureFlagValue;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
