<template>
    <div id="responsiveHeader" class="bg-background-light dark:bg-black">
        <div class="flex gap-5 xl:gap-9 justify-between items-center px-5 h-20">
            <div class="flex justify-start">
                <div>
                    <router-link :to="{name: 'Home'}">
                        <deskly-logo
                            v-if="!account?.logo"
                            class="w-auto h-10"
                            classes="text-black dark:text-white"
                        />
                        <img v-if="account?.logo" class="w-auto h-10" :src="account.logo" />
                    </router-link>
                </div>
            </div>
            <div v-if="!breakpointLgAndGreater" class="flex items-center">
                <button
                    v-cy="'mobile_menu'"
                    class="inline-flex justify-center items-center bg-background-light dark:bg-black rounded-md focus:ring-2 focus:ring-inset focus:outline-none"
                    type="button"
                    @click="toggleMobileMenu"
                >
                    <burger-icon class="text-gray dark:text-white" />
                </button>
            </div>

            <desktop-menu v-if="breakpointLgAndGreater && isAuthenticated && hasActiveLicense" />

            <div v-if="breakpointLgAndGreater" class="flex flex-1 justify-end items-center w-0">
                <VTooltip
                    auto-hide
                    :disabled="breakpointXlAndGreater"
                    placement="top"
                    :popper-triggers="['hover']"
                    :triggers="['hover']"
                >
                    <router-link
                        v-show="isAnalyst && isAuthenticated && hasActiveLicense"
                        class="flex items-center text-base hover:text-hover"
                        :to="{name: 'AdminDashboard'}"
                    >
                        <admin-icon />
                        <span class="ml-2 admin-area-link" v-text="__('app.header.admin')" />
                    </router-link>
                    <template #popper>
                        <span v-text="__('app.header.admin')" />
                    </template>
                </VTooltip>

                <language-dropdown v-if="!isMSTeams" class="mx-2.5" />

                <profile-dropdown v-if="isAuthenticated" :disable-logout="isMSTeams" />
            </div>
        </div>

        <mobile-menu
            v-if="!breakpointLgAndGreater"
            v-show="mobileMenuOpen"
            :active-license="hasActiveLicense"
            :authenticated="isAuthenticated"
            :current-locale="currentLocale"
            :is-m-s-teams="isMSTeams"
            @close="mobileMenuOpen = false"
        />
    </div>
</template>

<script>
import breakpoint from '../../mixins/breakpoint';
import BurgerIcon from '../icons/BurgerIcon.vue';
import DesklyLogo from '../logos/DesklyLogo.vue';
import DesktopMenu from './menu/DesktopMenu.vue';
import LanguageDropdown from './menu/LanguageDropdown.vue';
import MobileMenu from './menu/MobileMenu.vue';
import ProfileDropdown from './menu/ProfileDropdown.vue';
import {useEventBus} from '@vueuse/core';
import {mapState} from 'pinia';
import {useLocaleStore} from '../../../stores/localeStore';
import {useUserStore} from '../../../stores/userStore';
import {useAccountStore} from '../../../stores/accountStore';
import {useMsTeamsStore} from '../../../stores/msTeamsStore';
import AdminIcon from '../icons/AdminIcon.vue';

export default {
    components: {
        AdminIcon,
        DesktopMenu,
        MobileMenu,
        DesklyLogo,
        BurgerIcon,
        LanguageDropdown,
        ProfileDropdown,
    },
    mixins: [breakpoint],
    data: () => ({
        searchOpen: false,
        mobileMenuOpen: false,
    }),
    computed: {
        ...mapState(useLocaleStore, ['currentLocale']),
        ...mapState(useUserStore, [
            'user',
            'isAnalyst',
            'isManager',
            'isAdmin',
            'isSuperAdmin',
            'isAuthenticated',
            'hasActiveLicense',
        ]),
        ...mapState(useAccountStore, ['account']),
        ...mapState(useMsTeamsStore, ['isMSTeams']),
    },
    watch: {
        searchOpen(state) {
            document.body.style.overflow = state ? 'hidden' : 'scroll';
        },
        mobileMenuOpen(value) {
            document.body.style.overflow = value ? 'hidden' : 'scroll';
        },
    },
    created() {
        window.addEventListener('clickRouterLink', () => (this.mobileMenuOpen = false));

        useEventBus('close.search').on(() => this.searchOpen && this.toggleSearch());
    },
    methods: {
        toggleMobileMenu() {
            this.mobileMenuOpen = !this.mobileMenuOpen;
        },
    },
};
</script>
