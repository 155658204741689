<template>
    <div class="grid grid-cols-[min-content_1fr] gap-x-3 gap-y-4 w-full">
        <div
            class="flex justify-center items-center w-8 h-8 rounded"
            :class="severityClasses.iconWrapper"
        >
            <div
                class="flex justify-center items-center w-1/2 h-1/2 rounded-full"
                :class="{'bg-white': severity === 'success' || severity === 'error'}"
            >
                <check-icon v-if="severity === 'success'" class="w-1/2 h-1/2 text-green" />
                <triangle-exclamation-icon
                    v-else-if="severity === 'warn'"
                    class="w-full h-full text-white"
                />
                <info-icon v-else-if="severity === 'info'" class="w-full h-full text-white" />
                <close-icon-bold v-else-if="severity === 'error'" class="w-1/2 h-1/2 text-red" />
            </div>
        </div>
        <h4 class="flex items-center mr-1 font-bold text-base/[18px]" v-text="summary" />
        <div v-if="showDetails" class="flex flex-col col-start-2 gap-2 items-stretch">
            <p v-if="detail" class="text-base/5" :class="severityClasses.detail" v-html="detail" />
            <div class="flex mt-1 text-sm">
                <div v-if="link" class="underline me-2" @click="closeCallback">
                    <router-link
                        v-if="link.route"
                        active-class=""
                        exact-active-class=""
                        :to="link.route"
                        >{{ link.label }}</router-link
                    >
                    <a
                        v-else-if="link.url"
                        class="flex gap-1 items-center"
                        :class="severityClasses.link"
                        :href="link.url"
                        target="_blank"
                    >
                        <external-link-icon v-if="isExternalLink" class="!w-4 !h-4" />
                        {{ link.label }}
                    </a>
                    <a
                        v-else-if="link.callbackFn"
                        class="flex gap-1 items-center"
                        :class="severityClasses.link"
                        href="#"
                        target="_blank"
                        @click.prevent="link.callbackFn"
                    >
                        <external-link-icon v-if="isExternalLink" class="!w-4 !h-4" />
                        {{ link.label }}
                    </a>
                </div>
                <span
                    v-if="date"
                    class="ml-auto text-gray dark:text-gray-light"
                    v-text="relativeTimeFormatted"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from 'vue';
import CheckIcon from '../icons/CheckIcon.vue';
import InfoIcon from '../icons/InfoIcon.vue';
import CloseIconBold from '../icons/CloseIconBold.vue';
import configDay from '../../mixins/configDay';
import TriangleExclamationIcon from '../icons/TriangleExclamationIcon.vue';
import ExternalLinkIcon from '../icons/ExternalLinkIcon.vue';

const props = defineProps({
    severity: {
        type: String,
        required: true,
    },
    summary: {
        type: String,
        required: true,
    },
    detail: {
        type: String,
        default: null,
    },
    date: {
        type: [Date, null],
        default: null,
    },
    link: {
        type: [Object, null],
        default: () => null,
    },
    closeCallback: {
        type: Function,
        default: () => () => {},
    },
});

const relativeTimeFormatted = computed(() => {
    if (!props.date) {
        return '';
    }
    return configDay.methods.getFormattedDate(props.date);
});

const severityClasses = computed(() => {
    switch (props.severity) {
        case 'error':
            return {
                iconWrapper: 'bg-red-500 text-red-500',
                detail: 'text-red-500 dark:text-red-200',
                link: 'hover:text-red-500 dark:hover:text-red-400',
            };
        case 'success':
            return {
                iconWrapper: 'bg-green-500 text-green-500',
                detail: 'text-green-500 dark:text-green-200',
                link: 'hover:text-green-500 dark:hover:text-green-400',
            };
        case 'info':
            return {
                iconWrapper: 'bg-blue-500 text-blue-500',
                detail: 'text-blue-500 dark:text-blue-200',
                link: 'hover:text-blue-500 dark:hover:text-blue-400',
            };
        case 'warn':
            return {
                iconWrapper: 'bg-yellow-500 text-yellow-500',
                detail: 'text-yellow-600 dark:text-yellow-200',
                link: 'hover:text-yellow-500 dark:hover:text-yellow-400',
            };
        default:
            return '';
    }
});

const showDetails = computed(() => props.detail || props.date || props.link);

const isExternalLink = computed(() => {
    if (!props.link) {
        return false;
    }
    if (props.link?.route || props.link?.callbackFn) {
        return false;
    }
    const url = props.link.url;
    return url.trim().startsWith('http') && url.indexOf(window.location.origin) === -1;
});
</script>