<template>
    <nav class="flex gap-5 xl:gap-9 items-center">
        <div
            v-if="resourceTypes.length >= 2"
            class="flex items-center p-1.5 bg-background rounded-full"
        >
            <VTooltip
                v-for="resourceType in resourceTypes"
                :key="resourceType"
                auto-hide
                :disabled="breakpointXlAndGreater"
                placement="top"
                :popper-triggers="['hover']"
                :triggers="['hover']"
            >
                <router-link
                    active-class=""
                    class="group flex gap-2 items-center px-5 h-10 bg-background rounded-full cursor-pointer"
                    :class="[{'ml-1.5': !!index}]"
                    exact-active-class="bg-white active"
                    :to="{name: getRouteNameByResourceKey(resourceType.key)}"
                    @click.prevent="routerLink(resourceType.key)"
                >
                    <component
                        :is="`${resourceType.key.toLowerCase()}-icon`"
                        class="h-5 text-gray group-hover:text-hover group-[.active:not(:hover)]:text-primary"
                    />

                    <span
                        v-show="breakpointXlAndGreater"
                        class="group-[.active]:font-bold"
                        v-text="__(`app.resourceType.${resourceType.key}.name_plural`)"
                    />
                </router-link>
                <template #popper>
                    <span v-text="__(`app.resourceType.${resourceType.key}.name_plural`)" />
                </template>
            </VTooltip>
        </div>

        <router-link
            v-else
            class="text-base font-bold hover:text-hover cursor-pointer"
            :to="{name: getRouteNameByResourceKey(singleResourceKey)}"
            @click.prevent="routerLink(singleResourceKey)"
            >{{ __('app.header.book') }}</router-link
        >

        <router-link
            v-cy="'daybooking-overview-link'"
            class="text-base font-bold hover:text-hover"
            :to="{name: 'DayBookingOverview'}"
            >{{ __('app.header.dayBooking_overview') }}
        </router-link>
        <router-link
            v-if="!isAnonymous"
            class="text-base font-bold hover:text-hover"
            :to="{name: 'PresentList'}"
            >{{ __('app.header.present') }}
        </router-link>
        <div v-if="!isAnonymous && breakpointLgAndGreater && weekOverviewEnabled">
            <router-link class="text-base font-bold hover:text-hover" :to="{name: 'WeekOverview'}"
                >{{ __('app.header.week_overview') }}
            </router-link>
        </div>
    </nav>
</template>

<script>
import resourceTypes from '../../../mixins/resourceTypes';
import CheckIcon from '../../icons/CheckIcon.vue';
import SeatIcon from '../../icons/SeatIcon.vue';
import MeetingroomIcon from '../../icons/MeetingRoomIcon.vue';
import ZoneIcon from '../../icons/ZoneIcon.vue';
import breakpoint from '../../../mixins/breakpoint';
import API from '../../../../api/api.js';
import {useAccountStore} from '../../../../stores/accountStore';
import {mapState} from 'pinia';

export default {
    components: {
        CheckIcon,
        ZoneIcon,
        SeatIcon,
        MeetingroomIcon,
    },
    mixins: [resourceTypes, breakpoint],
    data: () => ({
        weekOverviewEnabled: false,
    }),
    computed: {
        ...mapState(useAccountStore, ['account']),
        isAnonymous() {
            return this.account.anonymous;
        },
        singleResourceKey() {
            return this.resourceTypes[0]?.key;
        },
    },
    created() {
        API.get('/api/flag/week-overview').then(response => {
            this.weekOverviewEnabled = response.status === 201;
        });
    },
    methods: {
        getRouteNameByResourceKey(resourceKey) {
            switch (resourceKey) {
                case 'meetingRoom':
                    return 'MeetingRoomDayBooking';
                case 'zone':
                    return 'ZoneDayBooking';
                case 'seat':
                default:
                    return 'SeatDayBooking';
            }
        },
        routerLink(resourceKey) {
            this.selectResource(resourceKey);

            this.$router.push({name: this.getRouteNameByResourceKey(resourceKey)});
        },
    },
};
</script>
