export default {
    root: ({attrs}) => ({
        class: [
            // Flex
            'inline-flex',
            [attrs.size === 's' ? 'w-[90px]' : 'w-full'],
            'h-[38px]',
        ],
    }),
    input: {
        root: ({parent, context}) => {
            return {
                class: [
                    'w-full',
                    // Display
                    'flex',
                    // Spacing
                    'p-3',
                    'm-0',
                    // Shape
                    'rounded-l-md',
                    'border',
                    {'border-surface-300': !parent.props.invalid},
                    // Invalid State
                    'invalid:focus:ring-error',
                    {'border-error': parent.props.invalid},
                    // States
                    {'hover:border-primary': !parent.props.invalid},
                    'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary/50 focus:z-10',
                    {'opacity-60 select-none pointer-events-none cursor-default': context.disabled},
                ],
            };
        },
    },
    buttongroup: () => ({
        class: [
            // Flex
            'flex',
            'flex-col',
        ],
    }),
    incrementbutton: {
        root: () => ({
            class: [
                // Display
                'flex flex-auto',
                // Size
                'w-12',
                // Alignment
                'items-center',
                'justify-center',
                'text-center align-bottom',
                // Position
                'relative',
                // Colors
                'text-white bg-primary border-primary',
                // Shape
                'border rounded-tr-md',
                //States
                'focus:outline-none focus:outline-offset-0 focus:ring',
                'hover:bg-hover hover:border-hover',
                //Misc
                'cursor-pointer overflow-hidden select-none',
            ],
        }),
        label: {
            class: 'h-0 w-0',
        },
    },
    decrementbutton: {
        root: () => ({
            class: [
                // Display
                'flex flex-auto',
                // Size
                'w-12',
                // Alignment
                'items-center',
                'justify-center',
                'text-center align-bottom',
                //Position
                'relative',
                // Colors
                'text-white bg-primary border-primary',
                // Shape
                'border rounded-br-md',
                //States
                'focus:outline-none focus:outline-offset-0 focus:ring',
                'hover:bg-hover hover:border-hover',
                //Misc
                'cursor-pointer overflow-hidden select-none',
            ],
        }),
        label: {
            class: 'h-0 w-0',
        },
    },
};
