export default {
    data() {
        return {
            desklyColors: {
                primary: '#1946b9',
                secondary: '#1946b9',
                hover: '#1971d6',
                accent: '#dbc3f4',
                accentLight: '#ebd9fe',
                accentLighter: '#f3e8fe',
                background: '#efebe7',
                backgroundLight: '#f5f2ef',
                backgroundLighter: '#ffffff',
            },
            customColors: {
                accent: '#b0b0b0',
                accentLight: '#c7c7c7',
                accentLighter: '#dfdfdf',
                background: '#ebebeb',
                backgroundLight: '#ffffff',
                backgroundLighter: '#f5f5f5',
            },
            colorsGlobal: {
                black: '#2c2c2c',
                blackLight: '#454545',
                white: '#ffffff',
                gray: '#b0b0b0',
                warning: '#fee9d9',
                error: '#ee5d5d',
                errorDark: '#cb4f4f',
                success: '#1daa99',
                inputIcon: '#6b7280',
            },
            chartColors: {
                resources: {
                    seat: '#C8DFA0',
                    green1: '#AEDF9F',
                    green2: '#A0DFAA',
                    green3: '#A0DFC3',
                    blue: '#A0DFDC',
                    zone: '#9FC8DF',
                    lila1: '#9FAEDF',
                    lila2: '#A99FDF',
                    rosa1: '#C39FDF',
                    rosa2: '#DC9FDF',
                    meetingRoom: '#DE9FC8',
                    red: '#DE9FAE',
                    orange1: '#DFA99F',
                    orange2: '#DFC39F',
                    yellow: '#DEDC9F',
                },
                status: {
                    office: '#707682',
                    mobile: '#A1A9BC',
                    absent: '#D4E0F9',
                },
            },
        };
    },
};
