import API from '../api';
import repositoryHelper from '../helpers/RepositoryHelper';
import ZoneForm from '../models/form/ZoneForm';
import ZoneView from '../models/view/ZoneView';
import PresentListUserView from '../models/view/PresentListUserView';

export default () => ({
    browseAdmin(roomId) {
        return API.get(`/api/admin/zone/${roomId}/all`)
            .then(response => API.handle(response))
            .catch(error => API.error(error))
            .then(raw => raw.map(singleRaw => new ZoneForm(singleRaw)));
    },
    read(zoneId) {
        return API.get(`/api/zone/get/${zoneId}`)
            .then(response => API.handle(response))
            .catch(error => API.error(error))
            .then(json => new ZoneForm(json));
    },
    readAdmin(zoneId) {
        return API.get(`/api/admin/zone/get/${zoneId}`)
            .then(API.handle)
            .catch(API.error)
            .then(json => new ZoneForm(json));
    },
    deleteAdmin(zoneId) {
        return API.delete(`/api/admin/zone/${zoneId}`)
            .then(response => API.handle(response))
            .catch(error => API.error(error));
    },
    edit(zone, authorizationGroups) {
        zone.dimensions = JSON.stringify(zone.dimensions);
        const data = {...zone, authorizationGroups};

        return API.postJSON(`/api/admin/zone/${zone.id}/edit`, data)
            .then(response => API.handle(response))
            .catch(error => API.error(error));
    },
    add(zone, authorizationGroups) {
        zone.dimensions = JSON.stringify(zone.dimensions);
        const data = {
            id: zone.id,
            name: zone.name,
            dimensions: zone.dimensions,
            capacity: zone.capacity,
            room: zone.room,
            blocked: zone.blocked,
            characteristics: zone.characteristics,
            authorizationGroups,
        };

        return API.postJSON('/api/admin/zone/create', data)
            .then(response => API.handle(response))
            .catch(error => API.error(error));
    },
    delete(zoneDayBookingId) {
        return API.delete(`/api/zoneDayBookings/${zoneDayBookingId}/delete`)
            .then(response => API.handle(response))
            .catch(error => API.error(error));
    },
    getNextAvailableZoneName(roomId) {
        return API.get(`/api/zone/${roomId}/nextAvailableZoneName`)
            .then(response => API.handle(response))
            .catch(error => API.error(error));
    },
    filter(location, room, characteristics, days, timeframe, from, until) {
        const url = repositoryHelper.methods.createFilterUrl(
            '/api/zone/filter',
            location,
            room,
            characteristics,
            days,
            timeframe,
            from,
            until
        );

        return API.get(url)
            .then(response => API.handle(response))
            .catch(error => API.error(error))
            .then(raw => ({
                zones: raw.zones.map(singleRaw => new ZoneView(singleRaw)),
                roomAvailableZones: raw.roomAvailableZones,
            }));
    },
    getUsers(zoneId, date) {
        return API.get(`/api/zone/users/${zoneId}/${date}`).then(response => {
            return response.json();
        });
    },
    getUsersForDates(zoneId, dates) {
        const searchParams = new URLSearchParams();

        searchParams.append('zone', zoneId);
        dates.forEach(date => searchParams.append('dates[]', date));

        return API.get(`/api/zone/users?${searchParams.toString()}`)
            .then(API.handle)
            .catch(API.error)
            .then(raw => raw.map(singleRaw => new PresentListUserView(singleRaw)));
    },
});
