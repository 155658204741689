import SeatRepository from '../../api/repositories/SeatRepository';
import {useFeatureFlagStore} from '../../stores/featureFlagStore';
import {until} from '@vueuse/core';

const SAVE_SEATLIST = 'SAVE_SEATLIST',
    SAVE_OCCUPIED_SEATLIST = 'SAVE_OCCUPIED_SEATLIST',
    SELECT_SEAT = 'SELECT_SEAT',
    RESET_SEAT = 'RESET_SEAT',
    SET_LOADING_STATE = 'SET_LOADING_STATE',
    RESET_SEATLIST = 'RESET_SEATLIST';

const state = () => ({
    filteredSeats: [],
    allSeats: [],
    roomAvailableSeats: [],
    occupiedSeats: [],
    selectedSeat: null,
    seatsLoading: false,
});

const actions = {
    /**
     *
     * @param {Object} rootState access state from other module
     * @param {function} dispatch access state from other module
     * @param {function} commit commits mutation
     */
    async fetchSeatList({rootState, commit}) {
        commit(SET_LOADING_STATE, true);

        const featureFlagStore = useFeatureFlagStore();
        await until(() => featureFlagStore.optimizedSeatDayBooking).not.toBeNull();

        if (featureFlagStore.optimizedSeatDayBooking) {
            const seatList = await SeatRepository().browse(
                rootState.filterLocation.selectedLocation,
                rootState.filterRoom.selectedRoom,
                rootState.filterCharacteristic.selectedCharacteristics,
                rootState.filterDay.selectedDays,
                rootState.filterTimeframe.selectedTimeframe,
                rootState.filterTime.from,
                rootState.filterTime.until
            );

            commit(SAVE_SEATLIST, {
                roomAvailableSeats: seatList.roomAvailableSeats,
                allSeats: seatList.allSeats,
                filteredSeats: seatList.filteredSeats,
            });

            commit(SAVE_OCCUPIED_SEATLIST, seatList.occupiedSeats);

            commit(SET_LOADING_STATE, false);
        } else {
            const seatList = await SeatRepository().filter(
                rootState.filterLocation.selectedLocation,
                rootState.filterRoom.selectedRoom,
                rootState.filterCharacteristic.selectedCharacteristics,
                rootState.filterDay.selectedDays,
                rootState.filterTimeframe.selectedTimeframe,
                rootState.filterTime.from,
                rootState.filterTime.until
            );

            commit(SAVE_SEATLIST, seatList);

            const occupiedSeatList = await SeatRepository().filterOccupied(
                rootState.filterLocation.selectedLocation,
                rootState.filterRoom.selectedRoom,
                rootState.filterCharacteristic.selectedCharacteristics,
                rootState.filterDay.selectedDays,
                rootState.filterTimeframe.selectedTimeframe,
                rootState.filterTime.from,
                rootState.filterTime.until
            );

            commit(SAVE_OCCUPIED_SEATLIST, occupiedSeatList);
            commit(SET_LOADING_STATE, false);
        }
    },
    /**
     *
     * @param {function} commit commits mutation
     * @param {String} seat id of seat to select
     */
    selectSeat({commit}, seat) {
        commit(SELECT_SEAT, seat);
    },
    /**
     *
     * @param {function} commit commits mutation
     */
    resetSeat({commit}) {
        commit(RESET_SEAT);
    },
    /**
     *
     * @param {function} commit commits mutation
     */
    resetSeatList({commit}) {
        commit(RESET_SEATLIST);
    },
};

const mutations = {
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Object} seatList object of filter results
     */
    [SAVE_SEATLIST](state, seatList) {
        state.filteredSeats = seatList.filteredSeats;
        state.allSeats = seatList.allSeats;
        state.roomAvailableSeats = seatList.roomAvailableSeats;
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Object} occupiedSeatList list of occupied seats by filter
     */
    [SAVE_OCCUPIED_SEATLIST](state, occupiedSeatList) {
        state.occupiedSeats = occupiedSeatList;
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {String} seat id of seat to select
     */
    [SELECT_SEAT](state, seat) {
        state.selectedSeat = seat;
    },
    /**
     *
     * @param {Object} state modules source of truth
     */
    [RESET_SEAT](state) {
        state.selectedSeat = null;
    },
    /**
     *
     * @param {Object} state modules source of truth
     */
    [RESET_SEATLIST](state) {
        state.filteredSeats = [];
        state.allSeats = [];
        state.roomAvailableSeats = [];
    },
    /**
     *
     * @param {Object} state modules source of truth
     * @param {Boolean} loadingState state of fetch
     */
    [SET_LOADING_STATE](state, loadingState) {
        state.seatsLoading = loadingState;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
