export default class ApiError {
    constructor(status, title, type, detail, data, silent = false) {
        this.status = status;
        this.title = title;
        this.type = type;
        this.detail = detail;
        this.data = data;
        this.silent = silent;
    }

    static fromResponse({status, title, type, detail, data}, silent) {
        return new ApiError(status, title, type, detail, data, silent);
    }

    toString() {
        return `[ERROR - ${this.type}]: ${this.detail}`;
    }
}
