import SubscriptionView from './SubscriptionView';

export default class AccountView {
    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.favicon = json.favicon ?? '';
        this.logo = json.logo ?? '';
        this.applicationTitle = json.applicationTitle ?? '';
        this.anonymous = json.anonymous;
        this.otherBooking = json.otherBooking;
        this.allowParallelBooking = json.allowParallelBooking;
        this.maxBookingDays = json.maxBookingDays;
        this.userAnonymization = json.userAnonymization;
        this.locale = (json.locale ?? '').toLowerCase();
        this.allowSeats = json.allowSeats;
        this.allowZones = json.allowZones;
        this.allowMeetingRooms = json.allowMeetingRooms;
        this.authorizationGroups = json.authorizationGroups;
        this.allowAvatar = json.allowAvatar;
        this.allowGravatar = json.allowGravatar;
        this.checkinRequired = json.checkinRequired;
        if (json.subscription) {
            this.subscription = new SubscriptionView(json.subscription);
        }
        this.komboADIntegration = json.komboADIntegration;
        this.komboHRIntegration = json.komboHRIntegration;
        this.isKomboPhotosScope = json.isKomboPhotosScope;
        this.komboIntegrations = this.komboADIntegration || this.komboHRIntegration;
        this.useKomboAvatar = json.useKomboAvatar;
        this.isMeetingRoomSync = json.isMeetingRoomSync;
        this.managersDeletingSeatingElements = json.managersDeletingSeatingElements;
    }
}
