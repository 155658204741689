export default {
    methods: {
        whiteLabelCheck(whitelabelInformation = false) {
            if (whitelabelInformation?.favicon) {
                this.setFavIcon(whitelabelInformation.favicon);
            }

            if (whitelabelInformation?.applicationTitle) {
                this.setTitle(whitelabelInformation.applicationTitle);
            }

            if (whitelabelInformation?.customColors) {
                this.setColors(
                    whitelabelInformation.primaryColor,
                    whitelabelInformation.secondaryColor,
                    whitelabelInformation.hoverColor
                );
                return;
            }

            this.setDefaultColors();
        },
        setFavIcon(iconUrl) {
            const favicon = document.querySelector('[rel=icon]');
            favicon.href = iconUrl;
        },
        setTitle(title) {
            document.title = title;
        },
        setGlobalColors() {
            document.documentElement.style.setProperty('--black', '#2c2c2c');
            document.documentElement.style.setProperty('--black-light', '#454545');
            document.documentElement.style.setProperty('--white', '#ffffff');
            document.documentElement.style.setProperty('--gray', '#b0b0b0');
            document.documentElement.style.setProperty('--gray-light', '#e6e6e6');
            document.documentElement.style.setProperty('--warning', '#fee9d9');
            document.documentElement.style.setProperty('--error', '#ee5d5d');
            document.documentElement.style.setProperty('--error-dark', '#cb4f4f');
            document.documentElement.style.setProperty('--success', '#1daa99');
        },
        setColors(primary, secondary, hover) {
            //dynamic colors
            document.documentElement.style.setProperty('--primary', primary);
            document.documentElement.style.setProperty('--secondary', secondary);
            document.documentElement.style.setProperty('--hover', hover);

            //whitelabel colors
            document.documentElement.style.setProperty('--accent', '#b0b0b0');
            document.documentElement.style.setProperty('--accent-light', '#c7c7c7');
            document.documentElement.style.setProperty('--accent-lighter', '#dfdfdf');
            document.documentElement.style.setProperty('--background', '#ebebeb');
            document.documentElement.style.setProperty('--background-light', '#ffffff');
            document.documentElement.style.setProperty('--background-lighter', '#f5f5f5');
        },
        setDefaultColors() {
            document.documentElement.style.setProperty('--primary', '#1946b9');
            document.documentElement.style.setProperty('--secondary', '#1946b9');
            document.documentElement.style.setProperty('--hover', '#1971d6');
            document.documentElement.style.setProperty('--accent', '#dbc3f4');
            document.documentElement.style.setProperty('--accent-light', '#ebd9fe');
            document.documentElement.style.setProperty('--accent-lighter', '#f3e8fe');
            document.documentElement.style.setProperty('--background', '#efebe7');
            document.documentElement.style.setProperty('--background-light', '#f5f2ef');
            document.documentElement.style.setProperty('--background-lighter', '#ffffff');
        },
    },
};
