export default {
    root: ({props}) => ({
        class: [
            'relative',

            // Flex & Alignment
            {'flex flex-col': props.scrollable && props.scrollHeight === 'flex'},

            // Size
            {'h-full': props.scrollable && props.scrollHeight === 'flex'},

            // Shape
            'border-spacing-0 border-separate',
        ],
    }),
    loadingoverlay: {
        class: [
            // Position
            'absolute',
            'top-0 left-0',
            'z-20',

            // Flex & Alignment
            'flex items-center justify-center',

            // Size
            'w-full h-full',

            // Color
            'bg-surface-100/20 dark:bg-surface-800/20',

            // Transition
            'transition duration-200',
        ],
    },
    loadingicon: {
        class: 'w-8 h-8 animate-spin',
    },
    wrapper: ({props}) => ({
        class: [
            {
                relative: props.scrollable,
                'flex flex-col grow': props.scrollable && props.scrollHeight === 'flex',
            },

            // Size
            {'h-full': props.scrollable && props.scrollHeight === 'flex'},
        ],
    }),
    header: ({props}) => ({
        class: [
            'font-semibold',

            // Shape
            props.showGridlines ? 'border-b' : 'border-0',

            // Spacing
            'py-0 px-0',

            // Color
            'bg-background-light dark:bg-background-light',
            'border-0',
            'text-black dark:text-white/80',
        ],
    }),
    table: {
        class: 'w-full border-spacing-0 border-separate bg-background p-5 pb-0 rounded-t-md',
    },
    thead: ({context}) => ({
        class: [
            {
                'top-0 z-40 sticky': context.scrollable,
            },
        ],
    }),
    tbody: ({instance, context}) => ({
        class: [
            'border-t border-surface-300 dark:border-surface-600',
            {
                'sticky z-20 font-semibold': instance.frozenRow && context.scrollable,
            },
        ],
    }),
    tfoot: ({context}) => ({
        class: [
            {
                'bottom-0 z-0': context.scrollable,
            },
        ],
    }),
    footer: {
        class: [
            'font-semibold',

            // Shape
            'border-t-0 border-t border-x-0',

            // Spacing
            'p-4',

            // Color
            'bg-background dark:bg-background',
            'border-0',
            'text-black dark:text-white/80',
        ],
    },
    column: {
        headercell: ({context, props}) => ({
            class: [
                'font-semibold',
                'text-lg',

                // Position
                {'sticky z-20 border-b': props.frozen || props.frozen === ''},
                {relative: context.resizable},

                // Alignment
                'text-left',

                // Shape
                {'border-r last:border-r-0 border-0 border-b border-solid': context?.showGridlines},

                // Spacing
                context?.size === 'small'
                    ? 'py-2.5 px-1'
                    : context?.size === 'large'
                    ? 'py-5 px-2'
                    : 'py-3.5 px-1.5',
                // Color
                (props.sortable === '' || props.sortable) && context.sorted
                    ? 'text-primary'
                    : 'bg-background text-black',
                (props.sortable === '' || props.sortable) && context.sorted
                    ? 'dark:text-hover'
                    : 'dark:text-white/80 dark:bg-background',
                'border-0 ',

                // States
                'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary dark:focus-visible:ring-hover',

                // Transition
                {'transition duration-200': props.sortable === '' || props.sortable},

                // Misc
                {'cursor-pointer': props.sortable === '' || props.sortable},
                {
                    'overflow-hidden space-nowrap bg-clip-padding': context.resizable,
                },
            ],
        }),
        headercontent: {
            class: 'flex items-center',
        },
        sort: ({context}) => ({
            class: [
                context.sorted ? 'text-primary' : 'text-surface-700',
                context.sorted ? 'dark:text-hover' : 'dark:text-white/80',
            ],
        }),
        bodycell: ({props, context, state, parent}) => ({
            class: [
                //Position
                {'sticky box-border border-b': parent.instance.frozenRow},
                {'sticky box-border border-b': props.frozen || props.frozen === ''},
                'text-lg',

                // Alignment
                'text-left',

                'border-0 border-b border-solid',
                {'last:border-r-0 border-r border-b': context?.showGridlines},
                {
                    'bg-surface-0 dark:bg-surface-800':
                        parent.instance.frozenRow || props.frozen || props.frozen === '',
                },

                // Spacing
                {'py-2.5 px-2': context?.size === 'small' && !state['d_editing']},
                {'py-5 px-4': context?.size === 'large' && !state['d_editing']},
                {
                    'py-3.5 px-3':
                        context?.size !== 'large' &&
                        context?.size !== 'small' &&
                        !state['d_editing'],
                },
                {'py-[0.6rem] px-2': state['d_editing']},

                // Color
                'border-gray dark:border-gray',

                // Misc
                'space-nowrap',
            ],
        }),
        footercell: ({context}) => ({
            class: [
                // Font
                'font-bold',

                // Alignment
                'text-left',

                // Shape
                {'border-r last:border-r-0': context?.showGridlines},
                'border-0 border-t border-solid',

                // Spacing
                context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-4',

                // Color
                'border-surface-200 dark:border-surface-700',
                'text-surface-700 dark:text-white/80',
                'bg-surface-0 dark:bg-surface-800',
            ],
        }),
        sorticon: {
            class: 'ml-2',
        },
        sortbadge: {
            class: [
                // Flex & Alignment
                'flex items-center justify-center align-middle',

                // Shape
                'rounded-full',

                // Size
                'w-[1.143rem] leading-[1.143rem]',

                // Spacing
                'ml-2',

                // Font
                'text-sm',

                // Color
                'text-primary dark:text-white',
                'bg-primary/10 dark:bg-hover/30',
            ],
        },
        columnfilter: {
            class: 'flex items-center ml-auto font-regular',
        },
        filteroverlay: {
            class: [
                // Position
                'absolute top-0 left-0',
                'mt-2',

                // Shape
                'border-0',
                'rounded-md',
                'shadow-md',

                // Size
                'min-w-[12.5rem]',

                // Color
                'bg-surface-0 dark:bg-surface-800',
                'text-surface-800 dark:text-white/80',
                'ring-1 ring-inset ring-surface-300 dark:ring-surface-700',
            ],
        },
        filtermatchmodedropdown: {
            root: ({state}) => ({
                class: [
                    // Display and Position
                    'flex',
                    'relative',

                    'text-md',

                    // Spacing
                    'mb-2',

                    // Shape
                    'w-full',
                    'rounded-md',
                    'shadow-sm',

                    // Color and Background
                    'bg-surface-0 dark:bg-surface-900',
                    {'ring-1 ring-inset ring-surface-300 dark:ring-surface-700': !state.focused},
                    'text-black',

                    // Transitions
                    'transition-all',
                    'duration-200',

                    // States
                    {
                        'outline-none outline-offset-0 ring-2 ring-primary dark:ring-hover':
                            state.focused,
                    },

                    // Misc
                    'cursor-default',
                    'select-none',
                ],
            }),
            input: ({props}) => ({
                class: [
                    //Font
                    'font-sans',
                    'leading-6',
                    'sm:text-sm',

                    // Display
                    'block',
                    'flex-auto',

                    // Color and Background
                    'bg-transparent',
                    'border-0',
                    {
                        'text-surface-800 dark:text-white/80': props.modelValue,
                        'text-surface-400 dark:text-surface-500': !props.modelValue,
                    },
                    'placeholder:text-surface-400 dark:placeholder:text-surface-500',

                    'py-1.5 px-3',

                    //Shape
                    'rounded-none',

                    // Transitions
                    'transition',
                    'duration-200',

                    // States
                    'focus:outline-none focus:shadow-none',

                    // Misc
                    'relative',
                    'cursor-pointer',
                    'overflow-hidden overflow-ellipsis',
                    'whitespace-nowrap',
                    'appearance-none',
                ],
            }),
        },
        filterrowitems: {
            class: 'py-1 list-none m-0',
        },
        filterseparator: {
            class: 'border-t border-surface-300',
        },
        filterrowitem: ({context}) => ({
            class: [
                // Font
                'sm:text-sm',
                'leading-none',
                {'font-normal': !context?.highlighted, 'font-bold': context?.highlighted},

                // Position
                'relative',

                // Shape
                'border-0',
                'rounded-none',

                // Spacing
                'm-0',
                'py-2 px-4',

                // Color
                {'text-surface-700 dark:text-white/80': !context?.highlighted},
                {
                    'bg-surface-0 dark:bg-surface-800 text-surface-700 dark:text-white/80':
                        !context?.highlighted,
                },
                {
                    'bg-accent-lighter dark:bg-hover text-black dark:text-surface-700':
                        context?.highlighted,
                },

                //States
                'hover:underline',
                'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary dark:focus-visible:ring-hover',

                // Transitions
                'transition-shadow',
                'duration-200',

                // Misc
                'cursor-pointer',
                'overflow-hidden',
                'whitespace-nowrap',
            ],
        }),
        filteroperator: {
            class: [
                // Spacing
                'p-4',

                // Shape
                'border-b border-solid',
                'rounded-t-md',

                // Color
                'text-surface-700 dark:text-white/80',
                'border-surface-200 dark:border-surface-700',
            ],
        },
        filteroperatordropdown: {
            root: ({state}) => ({
                class: [
                    // Display and Position
                    'flex',
                    'relative',

                    // Shape
                    'w-full',
                    'rounded-md',
                    'shadow-sm',

                    // Color and Background
                    'text-surface-800 dark:text-white/80',
                    'placeholder:text-surface-400 dark:placeholder:text-surface-500',
                    'bg-surface-0 dark:bg-surface-900',
                    {'ring-1 ring-inset ring-surface-300 dark:ring-surface-700': !state.focused},

                    // Transitions
                    'transition-all',
                    'duration-200',

                    // States
                    {
                        'outline-none outline-offset-0 ring-2 ring-primary dark:ring-hover':
                            state.focused,
                    },

                    // Misc
                    'cursor-default',
                    'select-none',
                ],
            }),
            input: {
                class: [
                    //Font
                    'font-sans',
                    'leading-6',
                    'sm:text-sm',

                    // Display
                    'block',
                    'flex-auto',

                    // Color and Background
                    'bg-transparent',
                    'border-0',
                    'text-surface-800 dark:text-white/80',
                    'placeholder:text-surface-400 dark:placeholder:text-surface-500',

                    'py-1.5 px-3',

                    //Shape
                    'rounded-none',

                    // Transitions
                    'transition',
                    'duration-200',

                    // States
                    'focus:outline-none focus:shadow-none',

                    // Misc
                    'relative',
                    'cursor-pointer',
                    'overflow-hidden overflow-ellipsis',
                    'whitespace-nowrap',
                    'appearance-none',
                ],
            },
            trigger: {
                class: [
                    // Flexbox
                    'flex items-center justify-center',
                    'shrink-0',

                    // Color and Background
                    'bg-transparent',
                    'text-surface-500',

                    // Size
                    'w-12',

                    // Shape
                    'rounded-tr-md',
                    'rounded-br-md',
                ],
            },
            panel: {
                class: [
                    // Position
                    'absolute top-0 left-0',
                    'mt-2',

                    // Shape
                    'border-0',
                    'rounded-md',
                    'shadow-md',

                    // Size
                    'min-w-[12.5rem]',

                    // Color
                    'bg-surface-0 dark:bg-surface-800',
                    'text-surface-800 dark:text-white/80',
                    'ring-1 ring-inset ring-surface-300 dark:ring-surface-700',
                ],
            },
            list: {
                class: 'py-1 list-none m-0',
            },
            item: ({context}) => ({
                class: [
                    // Font
                    'sm:text-sm',
                    'leading-none',
                    {'font-normal': !context?.highlighted, 'font-bold': context?.highlighted},

                    // Position
                    'relative',

                    // Shape
                    'border-0',
                    'rounded-none',

                    // Spacing
                    'm-0',
                    'py-2 px-4',

                    // Color
                    {'text-surface-700 dark:text-white/80': !context?.highlighted},
                    {
                        'bg-surface-0 dark:bg-surface-800 text-surface-700 dark:text-white/80':
                            !context?.highlighted,
                    },
                    {
                        'bg-primary dark:bg-hover text-white dark:text-surface-700':
                            context?.highlighted,
                    },

                    //States
                    'hover:bg-primary dark:hover:bg-hover hover:text-white dark:hover:text-surface-700',

                    // Transitions
                    'transition-shadow',
                    'duration-200',

                    // Misc
                    'cursor-pointer',
                    'overflow-hidden',
                    'whitespace-nowrap',
                ],
            }),
        },
        filterconstraint: {
            class: [
                // Spacing
                'p-4',

                // Shape
                'border-b border-solid',

                // Color
                'border-surface-200 dark:border-surface-700',
            ],
        },
        filteraddrule: {
            class: 'pt-4 pb-2 px-4',
        },
        filteraddrulebutton: {
            root: {
                class: [
                    'relative',

                    // Alignments
                    'items-center inline-flex text-center align-bottom justify-center',

                    // Sizes & Spacing
                    'text-sm px-2.5 py-1.5 min-w-[2rem] w-full',

                    // Shape
                    'rounded-md',

                    'bg-transparent border-transparent',
                    'text-primary dark:text-hover',
                    'hover:bg-primary/20',
                    'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-offset-current',
                    'focus:ring-primary dark:focus:ring-hover',

                    // Transitions
                    'transition duration-200 ease-in-out',

                    // Misc
                    'cursor-pointer overflow-hidden select-none',
                ],
            },
            label: {
                class: 'flex-auto grow-0',
            },
            icon: {
                class: 'mr-2',
            },
        },
        filterremovebutton: {
            root: {
                class: [
                    'relative',

                    // Alignments
                    'items-center inline-flex text-center align-bottom justify-center',

                    // Sizes & Spacing
                    'text-sm px-2.5 py-1.5 min-w-[2rem] w-full mt-2',

                    // Shape
                    'rounded-md',

                    'bg-transparent border-transparent',
                    'text-red-500 dark:text-red-400',
                    'hover:bg-red-300/20',
                    'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-offset-current',
                    'focus:ring-red-500 dark:focus:ring-red-400',

                    // Transitions
                    'transition duration-200 ease-in-out',

                    // Misc
                    'cursor-pointer overflow-hidden select-none',
                ],
            },
            label: {
                class: 'flex-auto grow-0',
            },
            icon: {
                class: 'mr-2',
            },
        },
        filterbuttonbar: {
            class: [
                // Flex & Alignment
                'flex items-center justify-between',

                // Space
                'py-4 px-4',
            ],
        },
        filterclearbutton: {
            root: {
                class: [
                    'relative',

                    // Alignments
                    'items-center inline-flex text-center align-bottom justify-center',

                    // Sizes & Spacing
                    'text-sm px-2.5 py-1.5 min-w-[2rem]',

                    // Shape
                    'rounded-md shadow-sm border-0',

                    'text-primary ring-1 ring-primary hover:bg-primary/20',
                    'hover:bg-primary/20',
                    'focus:ring-primary dark:focus:ring-hover',

                    // Transitions
                    'transition duration-200 ease-in-out',

                    // Misc
                    'cursor-pointer overflow-hidden select-none',
                ],
            },
        },
        filterapplybutton: {
            root: {
                class: [
                    'relative',

                    // Alignments
                    'items-center inline-flex text-center align-bottom justify-center',

                    // Sizes & Spacing
                    'text-sm px-2.5 py-1.5 min-w-[2rem]',

                    // Shape
                    'rounded-md border-0',

                    'text-white dark:text-surface-900',
                    'bg-primary dark:bg-hover',
                    'ring-1 ring-primary dark:ring-hover',
                    'hover:bg-primary dark:hover:bg-primary hover:ring-primary dark:hover:ring-primary/80',
                    'focus:ring-primary dark:focus:ring-hover',

                    // Transitions
                    'transition duration-200 ease-in-out',

                    // Misc
                    'cursor-pointer overflow-hidden select-none',
                ],
            },
        },
        filtermenubutton: ({context}) => ({
            class: [
                'relative',
                // Flex & Alignment
                'inline-flex items-center justify-center',

                // Size
                'w-8 h-8 text-md min-w-8',

                // Spacing
                'ml-2',

                // Shape
                'rounded-full',

                // Color
                {'bg-primary/10 text-primary': context.active},
                'dark:text-white/70 dark:hover:text-white/80 dark:bg-surface-800',

                // States
                'hover:text-surface-700 hover:bg-surface-300/20',
                'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary dark:focus:ring-hover',

                // Transition
                'transition duration-200',

                // Misc
                'cursor-pointer no-underline overflow-hidden',
            ],
        }),
        headerfilterclearbutton: ({context}) => ({
            class: [
                'relative',

                'w-8 h-8',

                // Flex & Alignment
                'inline-flex items-center justify-center',
                'text-left',

                // Shape
                'border-none',

                // Spacing
                'm-0 p-0 ml-2',

                // Color
                'bg-transparent',

                // Misc
                'cursor-pointer no-underline overflow-hidden select-none',
                {
                    invisible: !context.hidden,
                },
            ],
        }),
        rowtoggler: {
            class: [
                'relative',

                // Flex & Alignment
                'inline-flex items-center justify-center',
                'text-left',

                // Spacing
                'm-0 p-0',

                // Size
                'w-8 h-8',

                // Shape
                'border-0 rounded-full',

                // Color
                'text-surface-500 dark:text-white/70',
                'bg-transparent',
                'focus-visible:outline-none focus-visible:outline-offset-0',
                'focus-visible:ring-2 focus-visible:ring-primary dark:focus-visible:ring-hover',

                // Transition
                'transition duration-200',

                // Misc
                'overflow-hidden',
                'cursor-pointer select-none',
            ],
        },
        columnresizer: {
            class: [
                'block',

                // Position
                'absolute top-0 right-0',

                // Sizing
                'w-2 h-full',

                // Spacing
                'm-0 p-0',

                // Color
                'border border-transparent',

                // Misc
                'cursor-col-resize',
            ],
        },
        rowreordericon: {
            class: 'cursor-move',
        },
        roweditorinitbutton: {
            class: [
                'relative',

                // Flex & Alignment
                'inline-flex items-center justify-center',
                'text-left',

                // Size
                'w-8 h-8',

                // Shape
                'border-0 rounded-full',

                // Color
                'text-surface-700 dark:text-white/70',
                'border-transparent',

                // States
                'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary dark:focus:ring-hover',
                'hover:text-surface-700 hover:bg-surface-300/20',

                // Transition
                'transition duration-200',

                // Misc
                'overflow-hidden',
                'cursor-pointer select-none',
            ],
        },
        roweditorsavebutton: {
            class: [
                'relative',

                // Flex & Alignment
                'inline-flex items-center justify-center',
                'text-left',

                // Size
                'w-8 h-8',

                // Shape
                'border-0 rounded-full',

                // Color
                'text-surface-700 dark:text-white/70',
                'border-transparent',

                // States
                'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary dark:focus:ring-hover',
                'hover:text-surface-700 hover:bg-surface-300/20',

                // Transition
                'transition duration-200',

                // Misc
                'overflow-hidden',
                'cursor-pointer select-none',
            ],
        },
        roweditorcancelbutton: {
            class: [
                'relative',

                // Flex & Alignment
                'inline-flex items-center justify-center',
                'text-left',

                // Size
                'w-8 h-8',

                // Shape
                'border-0 rounded-full',

                // Color
                'text-surface-700 dark:text-white/70',
                'border-transparent',

                // States
                'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary dark:focus:ring-hover',
                'hover:text-surface-700 hover:bg-surface-300/20',

                // Transition
                'transition duration-200',

                // Misc
                'overflow-hidden',
                'cursor-pointer select-none',
            ],
        },
        radiobuttonwrapper: {
            class: [
                'relative',

                // Flex & Alignment
                'inline-flex align-bottom',

                // Size
                'w-4 h-4',

                // Misc
                'cursor-pointer select-none',
            ],
        },
        radiobutton: ({context}) => ({
            class: [
                // Flexbox
                'flex justify-center items-center',

                // Size
                'w-4 h-4',
                'text-sm',
                'font-medium',

                // Shape
                'border-2',
                'rounded-full',

                // Color
                {'text-surface-700 dark:text-white/80': !context.checked},
                {'bg-surface-0 dark:bg-surface-900': !context.checked},
                {'border-surface-300 dark:border-surface-700': !context.checked},
                {'border-primary dark:border-hover': context.checked},

                // States
                {'hover:border-primary dark:hover:border-hover': !context.disabled},
                {
                    'ring-2 ring-offset-2 ring-offset-surface-0 dark:ring-offset-surface-800 ring-primary dark:ring-hover':
                        context.focused,
                },

                // Transition
                'transition duration-200 ease-in-out',

                // Misc
                {'cursor-default opacity-60': context.disabled},
            ],
        }),
        radiobuttonicon: {
            class: 'hidden',
        },
        headercheckboxwrapper: {
            class: [
                'relative',

                // Flex & Alignment
                'inline-flex align-bottom',

                // Size
                'w-4',
                'h-4',

                // Misc
                'cursor-default select-none',
            ],
        },
        headercheckbox: ({context}) => ({
            class: [
                // Flex & Alignment
                'flex items-center justify-center',

                // Shape
                'rounded',
                'border',

                // Size
                'w-4',
                'h-4',

                // Color
                'text-surface-600',
                {
                    'border-surface-300 bg-surface-0 dark:border-surface-700 dark:bg-surface-900':
                        !context.checked,
                    'border-primary bg-primary dark:border-hover dark:bg-hover': context.checked,
                },

                // States
                'focus:outline-none focus:outline-offset-0',
                {
                    'ring-2 ring-primary dark:ring-hover': context.focused,
                },

                // Transition
                'transition-colors duration-200',

                // Misc
                {'cursor-default opacity-60': context.disabled},
            ],
        }),
        headercheckboxicon: {
            class: [
                // Font
                'text-normal',

                // Size
                'w-3',
                'h-3',

                // Color
                'text-white dark:text-surface-900',

                // Transition
                'transition-all duration-200',
            ],
        },
        checkboxwrapper: {
            class: [
                'relative',

                // Flex & Alignment
                'inline-flex align-bottom',

                // Size
                'w-4',
                'h-4',

                // Misc
                'cursor-default select-none',
            ],
        },
        checkbox: ({context}) => ({
            class: [
                // Flex & Alignment
                'flex items-center justify-center',

                // Shape
                'rounded',
                'border',

                // Size
                'w-4',
                'h-4',

                // Color
                'text-surface-600',
                {
                    'border-surface-300 bg-surface-0 dark:border-surface-700 dark:bg-surface-900':
                        !context.checked,
                    'border-primary bg-primary dark:border-hover dark:bg-hover': context.checked,
                },

                // States
                'focus:outline-none focus:outline-offset-0',
                {
                    'ring-2 ring-primary dark:ring-hover': context.focused,
                },

                // Transition
                'transition-colors duration-200',

                // Misc
                {'cursor-default opacity-60': context.disabled},
            ],
        }),
        checkboxicon: {
            class: [
                // Font
                'text-normal',

                // Size
                'w-3',
                'h-3',

                // Color
                'text-white dark:text-surface-900',

                // Transition
                'transition-all duration-200',
            ],
        },
        transition: {
            enterFromClass: 'opacity-0 scale-y-[0.8]',
            enterActiveClass:
                'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
            leaveActiveClass: 'transition-opacity duration-100 ease-linear',
            leaveToClass: 'opacity-0',
        },
    },
    bodyrow: ({context, props}) => ({
        class: [
            // Color
            'dark:text-white/80',
            {'bg-surface-100 dark:bg-surface-500/30': context.selected && context.stripedRows},
            {'bg-background dark:bg-surface-500/30': context.selected && !context.stripedRows},
            {'bg-background-light text-black dark:bg-surface-800': !context.selected},
            {'bg-background-light dark:bg-surface-800': props.frozenRow},
            {
                'odd:bg-background-light odd:text-black dark:odd:bg-surface-800 even:bg-background even:text-black dark:even:bg-surface-900/60':
                    context.stripedRows && !context.selected,
            },

            // State
            {
                'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-hover/50 ring-inset dark:focus:ring-primary/50':
                    props.selectionMode,
            },
            {
                'hover:bg-surface-300/20 hover:text-surface-600':
                    props.selectionMode && !context.selected,
            },

            // Transition
            {
                'transition duration-200':
                    (props.selectionMode && !context.selected) || props.rowHover,
            },

            // Misc
            {'cursor-pointer': props.selectionMode},
        ],
    }),
    rowexpansion: {
        class: 'bg-surface-0 dark:bg-surface-800 text-surface-600 dark:text-white/80',
    },
    rowgroupheader: {
        class: [
            'sticky z-20',
            'bg-surface-0 text-surface-600 dark:text-white/70',
            'dark:bg-surface-800',
        ],
    },
    rowgroupfooter: {
        class: [
            'sticky z-20',
            'bg-surface-0 text-surface-600 dark:text-white/70',
            'dark:bg-surface-800',
        ],
    },
    rowgrouptoggler: {
        class: [
            'relative',

            // Flex & Alignment
            'inline-flex items-center justify-center',
            'text-left',

            // Spacing
            'm-0 p-0',

            // Size
            'w-8 h-8',

            // Shape
            'border-0 rounded-full',

            // Color
            'text-surface-500 dark:text-white/70',
            'bg-transparent',
            'focus-visible:outline-none focus-visible:outline-offset-0',
            'focus-visible:ring-2 focus-visible:ring-primary dark:focus-visible:ring-hover',

            // Transition
            'transition duration-200',

            // Misc
            'overflow-hidden',
            'cursor-pointer select-none',
        ],
    },
    rowgrouptogglericon: {
        class: 'inline-block w-4 h-4',
    },
    resizehelper: {
        class: 'absolute hidden w-[2px] z-20 bg-primary dark:bg-hover',
    },
    emptymessage: {
        class: 'bg-background-light',
    },
};
