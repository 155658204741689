import API from '../api/api';
import {ref} from 'vue';
import {computedAsync} from '@vueuse/core';
import {defineStore} from 'pinia';
import {useUserStore} from './userStore';

export const useFeatureFlagStore = defineStore('featureFlags', () => {
    const userStore = useUserStore();

    const optimizedSeatDayBookingLoading = ref(false);

    const optimizedSeatDayBooking = computedAsync(
        async () => {
            if (!userStore.isAuthenticated) {
                return false;
            }
            const response = await API.get('/api/flag/optimized-seat-day-booking');
            return response.status === 201;
        },
        null,
        optimizedSeatDayBookingLoading
    );

    return {optimizedSeatDayBooking, optimizedSeatDayBookingLoading};
});
