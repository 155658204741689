<template>
    <div
        v-if="!isAuthenticated"
        class="hidden lg:flex justify-center items-center p-4 w-full bg-white"
    >
        <a :href="`https://www.desk.ly?lang=${currentLocale}`" target="_blank">
            <button-link black class="mr-10" is-external :text="__('app.login.footer.about')" />
        </a>
        <a :href="`https://www.desk.ly/impressum?lang=${currentLocale}`" target="_blank">
            <button-link black class="mr-10" is-external :text="__('app.login.footer.imprint')" />
        </a>
        <a :href="`https://www.desk.ly/datenschutz?lang=${currentLocale}`" target="_blank">
            <button-link black class="mr-10" is-external :text="__('app.login.footer.privacy')" />
        </a>
        <a :href="`https://www.desk.ly/agb?lang=${currentLocale}`" target="_blank">
            <button-link
                black
                class="mr-10"
                is-external
                :text="__('app.login.footer.business_terms')"
            />
        </a>
    </div>
</template>

<script>
import ButtonLink from '../../shared/components/core/ButtonLink.vue';
import {mapState} from 'pinia';
import {useLocaleStore} from '../../stores/localeStore';
import {useUserStore} from '../../stores/userStore';

export default {
    components: {ButtonLink},
    computed: {
        ...mapState(useLocaleStore, ['currentLocale']),
        ...mapState(useUserStore, ['isAuthenticated']),
    },
};
</script>
